export default [
  {
    path: '/admin/instances-list/',
    name: 'admin-instances-list',
    meta: {
      pageTitle: 'Instances',
    },
    component: () => import('@/views/admin/instances/instances-list/InstancesList.vue'),
  },
  {
    path: '/admin/create-instance/',
    name: 'admin-create-instance',
    meta: {
      pageTitle: 'Adding New Instance',
      navActiveLink: 'admin-instances-list',
    },
    component: () => import('@/views/admin/instances/instance-settings/Instance.vue'),
  },
  {
    path: '/admin/edit-instance/:id',
    name: 'admin-edit-instance',
    meta: {
      pageTitle: 'Edit Instance',
      navActiveLink: 'admin-instances-list',
    },
    component: () => import('@/views/admin/instances/instance-settings/Instance.vue'),
  },
  {
    path: '/admin/reports',
    name: 'admin-reports',
    component: () => import('@/views/admin/reports-v2/reports/reports-list/ReportsList.vue'),
    meta: {
      pageTitle: 'Reports List',
    },
  },
  {
    path: '/admin/report-details',
    name: 'admin-report-details',
    component: () => import('@/views/admin/reports-v2/reports/report-details/ReportDetails.vue'),
    meta: {
      navActiveLink: 'admin-reports',
    },
  },
  {
    path: '/admin/reports-view-list',
    name: 'admin-reports-view-list',
    component: () => import('@/views/admin/reports-v2/reports-view/reports-view-list/ReportsViewList.vue'),
    meta: {
      pageTitle: 'Report Types',
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/admin/report-view',
    name: 'admin-report-view',
    component: () => import('@/views/admin/reports-v2/reports-view/reports-view-detail/ReportViewDetail.vue'),
    meta: {
      pageTitle: 'Report View',
      navActiveLink: 'admin-reports-view-list',
    },
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/admin/admins/admins-list/AdminsList.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/admin/admin-profile',
    name: 'admin-user-add',
    component: () => import('@/views/admin/admins/admin-profile/AdminProfile.vue'),
    meta: {
      pageTitle: 'Add Admin',
      navActiveLink: 'admin-users',
    },
  },
  {
    path: '/admin/admin-profile/:id',
    name: 'admin-profile',
    component: () => import('@/views/admin/admins/admin-profile/AdminProfile.vue'),
    meta: {
      pageTitle: 'Admin Info',
      navActiveLink: 'admin-users',
    },
  },
  {
    path: '/admin/roles',
    name: 'admin-roles-list',
    component: () => import('@/views/admin/roles/roles-list/RolesList.vue'),
    meta: {
      pageTitle: 'Roles & Permissions',
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/admin/roles/:id',
    name: 'admin-role-edit',
    component: () => import('@/views/admin/roles/role-edit/RoleEdit.vue'),
    meta: {
      navActiveLink: 'admin-roles-list',
    },
  },
  {
    path: '/admin/actions-audit',
    name: 'admin-actions-audit',
    component: () => import('@/views/admin/actions-audit/ActionsAudit.vue'),
    meta: {
      pageTitle: 'Actions Audit',
    },
    props: route => ({ query: route.query }),
  },
  {
    path: '/admin/admin-programs-settings',
    name: 'admin-programs-settings',
    component: () => import('@/views/admin/settings-programs/SettingsPrograms.vue'),
    meta: {
      pageTitle: 'Settings Programs',
    },
  },
  {
    path: '/admin/form-folders',
    name: 'admin-form-folders',
    component: () => import('@/views/admin/forms/form-folders/FormFolders.vue'),
    meta: {
      pageTitle: 'Forms',
    },
  },
  {
    path: '/admin/form-folder-detail/:id',
    name: 'admin-form-folder-detail',
    component: () => import('@/views/admin/forms/form-folders/FormFolderDetails.vue'),
    meta: {
      navActiveLink: 'admin-form-folders',
    },
  },
  {
    path: '/admin/form-edit/:id',
    name: 'admin-form-edit',
    component: () => import('@/views/admin/forms/form-edit/FormEdit.vue'),
    meta: {
      pageTitle: 'Forms',
      navActiveLink: 'admin-form-folders',
    },
  },
]
